<template>
  <div style="height: 80vh; overflow-y: auto;">
    <div class="card card-custom gutter-b">
      <div class="card-header border-0 pb-0">
        <h3 class="card-title">Cumulative</h3>
      </div>
      <div class="card-body p-0">
        <DailyHistoryCumulative
          v-if="isCumulativeDaysDataReady"
          :traffic="cumulativeDaysData"
        ></DailyHistoryCumulative>
        <div v-else class="col-12 text-center pb-5">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </div>

    <div class="separator separator-dashed my-5"></div>

    <div class="card card-custom gutter-b">
      <div class="card-header border-0 pb-0">
        <h3 class="card-title">Full</h3>
      </div>
      <div class="card-body p-0">
        <DailyHistoryFull
          v-if="isFullDaysDataReady"
          :traffic="fullDaysData"
          :today-traffic="todayTraffic"
        >
        </DailyHistoryFull>
        <div v-else class="col-12 text-center pb-5">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import addDays from 'date-fns/addDays'
import addMinutes from 'date-fns/addMinutes'
import { format } from 'date-fns'
import ApiService from '@/core/services/api.service'
import { mapGetters } from 'vuex'
import DailyHistoryCumulative from './DailyHistoryCumulative'
import DailyHistoryFull from './DailyHistoryFull'

export default {
  name: 'DailyHistory',
  components: {
    DailyHistoryCumulative,
    DailyHistoryFull,
  },
  props: {
    scale: {
      type: Number,
    },
    todayTraffic: {
      type: Number,
    },
  },
  data() {
    return {
      days: [],

      cumulativeDaysData: [],
      fullDaysData: [],

      isCumulativeDaysDataReady: false,
      isFullDaysDataReady: false,

      nextFetchCumulative: null,
      lastFetchCumulative: null,
      lastFetchFull: null,
      nextFetchFull: null,
    }
  },
  computed: { ...mapGetters(['currentUserCompanyOib']) },
  mounted() {
    this.nextFetchFull = addMinutes(new Date(), 30)
    this.lastFetchFull = new Date()

    let i
    for (i = 0; i < this.scale; i++) this.days.push(addDays(new Date(), -7 * i))

    this.days.forEach((day, key) => {
      this.fullDaysData.push({
        day: format(day, 'dd.MM.yyyy'),
        dayFormat: day,
        value: 0,
      })

      ApiService.get(
        'company_transactions',
        `?oib=${this.currentUserCompanyOib}&date=${format(
          day,
          'yyyy-MM-dd'
        )}&order[createdAt]=desc&pagination=false`
      ).then(({ data }) => {
        data['hydra:member'].forEach(({ total, date }) => {
          const row = this.fullDaysData.findIndex(
            (day) => day.day === format(new Date(date), 'dd.MM.yyyy')
          )
          this.fullDaysData[row].value += parseFloat(total)
        })

        if (this.fullDaysData.length === key + 1) {
          setTimeout(() => {
            this.isFullDaysDataReady = true
          }, 1000)
          this.calcCumulative()
        }
      })
    })
  },
  methods: {
    calcCumulative() {
      this.days.forEach((day) => {
        const start = `${format(new Date(day), "yyyy-MM-dd'T'HH:mm").slice(
          0,
          10
        )}T00:00`
        const current = format(new Date(day), "yyyy-MM-dd'T'HH:mm")
        ApiService.get(
          'transactions',
          `?oib=${this.currentUserCompanyOib}&createdAt[after]=${start}&createdAt[before]=${current}&pagination=false`
        ).then(({ data }) => {
          this.cumulativeDaysData.push({
            day: format(day, 'dd.MM.yyyy'),
            dayFormat: day,
            value: 0,
          })
          data['hydra:member'].forEach(({ amount, createdAt }) => {
            const row = this.cumulativeDaysData.findIndex(
              (day) => day.day === format(new Date(createdAt), 'dd.MM.yyyy')
            )
            this.cumulativeDaysData[row].value += parseFloat(amount)
          })

          if (this.cumulativeDaysData.length === this.scale) {
            setTimeout(() => {
              this.isCumulativeDaysDataReady = true
            }, 1000)
          }
        })
      }, undefined)
    },
  },
}
</script>
