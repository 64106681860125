<template>
  <div>
    <VueApexCharts
      v-if="isReady"
      :options="options"
      :series="series"
    ></VueApexCharts>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { mapGetters } from 'vuex'

export default {
  name: 'DailyHistoryCumulative',
  components: {
    VueApexCharts,
  },
  props: {
    traffic: {
      type: Array,
    },
  },
  data() {
    return {
      options: {
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            dataLabels: {
              orientation: 'vertical',
              position: 'bottom',
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -2,
          offsetY: 7,
          formatter(val) {
            return (
              val
                .toFixed(2)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
            )
          },
        },
        grid: {
          show: true,
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
            rotate: -90,
          },
        },
        yaxis: {
          labels: {
            show: true,
            formatter(val) {
              return (
                val
                  .toFixed(2)
                  .replace('.', ',')
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
              )
            },
          },
        },
        legend: {
          show: false,
        },
        title: {},
        colors: [
          function ({ dataPointIndex }) {
            if (dataPointIndex === 30) return '#989898'
            else return '#269ffb'
          },
        ],
      },
      series: [
        {
          name: 'Day',
          data: [],
        },
      ],
      today: null,
      isReady: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserCompanyOib']),
  },
  mounted() {
    const mq = window.matchMedia('(min-width: 480px)')

    if (!mq.matches) {
      this.options.chart.type = 'area'
      this.options.xaxis.labels.show = false
      this.options.yaxis.show = false
    }

    this.traffic = this.traffic.sort(function (a, b) {
      return new Date(b.dayFormat) - new Date(a.dayFormat)
    })

    this.traffic.forEach((day) => {
      this.series[0].data.push(day.value)
      this.options.xaxis.categories.push(day.day)
    })

    let totalDays = 0
    let totalNoNullDays = 0
    let allDaysSum = 0
    this.series[0].data.forEach((day) => {
      totalDays++
      allDaysSum += day
      if (day === 0) totalNoNullDays++
    })

    let averageFull = allDaysSum / (totalDays - totalNoNullDays)
    let averageIndex = averageFull * 0.15

    let totalNoIndexDays = 0
    let allIndexDaysSum = 0
    this.series[0].data.forEach((day) => {
      if (day < averageIndex && day !== 0) {
        totalNoIndexDays++
      } else {
        allIndexDaysSum += day
      }
    })

    let averageFinal =
      allIndexDaysSum / (totalDays - totalNoNullDays - totalNoIndexDays)

    // Average
    this.series[0].data.push(averageFinal)
    this.options.xaxis.categories.push('Average')

    this.isReady = true
  },
}
</script>
