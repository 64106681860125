<template>
  <div class="table-responsive mb-0 pb-0">
    <table
      v-if="isGenerated"
      class="table table-head-custom table-vertical-center table-head-bg"
    >
      <thead>
        <tr class="text-uppercase">
          <th class="pl-6">
            <span @click="orderByDeviceName" class="cursor-pointer">
              <span
                class="pt-2"
                :class="{ 'text-primary': deviceNameOrder.isActive }"
              >
                {{ $t('COMPANIES.DEVICE_NAME') }}
              </span>
              <span
                class="svg-icon svg-icon-sm pl-2"
                :class="{
                  'svg-icon-primary': deviceNameOrder.isActive,
                }"
              >
                <inline-svg
                  v-if="deviceNameOrder.query === 'asc'"
                  src="media/svg/icons/Navigation/Down-2.svg"
                />
                <inline-svg v-else src="media/svg/icons/Navigation/Up-2.svg" />
              </span>
            </span>
          </th>
          <th class="text-right">{{ $t('GENERAL.LOCATION') }}</th>
          <th
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="text-right"
          >
            {{ $t('GENERAL.LESSOR') }}
          </th>
          <th
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="text-right"
          >
            Netto {{ $t('GENERAL.COIN') }}
          </th>
          <th
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="text-right cursor-pointer"
          >
            <span @click="orderByCoin" class="cursor-pointer">
              <span
                class="pt-2"
                :class="{ 'text-primary': coinOrder.isActive }"
              >
                <span v-b-tooltip.hover="'Brutto Coin'">{{
                  $t('GENERAL.COIN')
                }}</span></span
              >
              <span
                class="svg-icon svg-icon-sm pl-2"
                :class="{
                  'svg-icon-primary': coinOrder.isActive,
                }"
              >
                <inline-svg
                  v-if="coinOrder.query === 'asc'"
                  src="media/svg/icons/Navigation/Down-2.svg"
                />
                <inline-svg v-else src="media/svg/icons/Navigation/Up-2.svg" />
              </span>
            </span>
          </th>
          <th
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="text-right"
          >
            Netto SMS
          </th>
          <th
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="text-right cursor-pointer"
          >
            <span @click="orderBySms" class="cursor-pointer">
              <span class="pt-2" :class="{ 'text-primary': smsOrder.isActive }">
                <span v-b-tooltip.hover="'Brutto SMS'">SMS</span></span
              >
              <span
                class="svg-icon svg-icon-sm pl-2"
                :class="{
                  'svg-icon-primary': smsOrder.isActive,
                }"
              >
                <inline-svg
                  v-if="smsOrder.query === 'asc'"
                  src="media/svg/icons/Navigation/Down-2.svg"
                />
                <inline-svg v-else src="media/svg/icons/Navigation/Up-2.svg" />
              </span>
            </span>
          </th>
          <th
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="text-right"
          >
            Netto CS
          </th>
          <th
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="text-right"
          >
            Pdv
          </th>
          <th class="text-right cursor-pointer">
            <span @click="orderByCs" class="cursor-pointer">
              <span class="pt-2" :class="{ 'text-primary': cSOrder.isActive }">
                <span
                  v-if="currentUserCompanyModule[0] === 'gaming'"
                  v-b-tooltip.hover="'Brutto Coin + SMS'"
                  >CS</span
                >
                <span v-else v-b-tooltip.hover="'Brutto Total'">Total</span>
              </span>
              <span
                class="svg-icon svg-icon-sm pl-2"
                :class="{
                  'svg-icon-primary': cSOrder.isActive,
                }"
              >
                <inline-svg
                  v-if="cSOrder.query === 'asc'"
                  src="media/svg/icons/Navigation/Down-2.svg"
                />
                <inline-svg v-else src="media/svg/icons/Navigation/Up-2.svg" />
              </span>
            </span>
          </th>
          <th
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="text-right"
          >
            <span @click="orderByLoyalty" class="cursor-pointer">
              <span
                class="pt-2"
                :class="{ 'text-primary': loyaltyOrder.isActive }"
              >
                <span>Loyalty</span></span
              >
              <span
                class="svg-icon svg-icon-sm pl-2"
                :class="{
                  'svg-icon-primary': loyaltyOrder.isActive,
                }"
              >
                <inline-svg
                  v-if="loyaltyOrder.query === 'asc'"
                  src="media/svg/icons/Navigation/Down-2.svg"
                />
                <inline-svg v-else src="media/svg/icons/Navigation/Up-2.svg" />
              </span>
            </span>
          </th>
          <th
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="text-right cursor-pointer pr-6"
          >
            <span @click="orderByTotal" class="cursor-pointer">
              <span
                class="pt-2"
                :class="{ 'text-primary': totalOrder.isActive }"
              >
                <span v-b-tooltip.hover="'Coin + SMS + Loyalty'"
                  >Total</span
                ></span
              >
              <span
                class="svg-icon svg-icon-sm pl-2"
                :class="{
                  'svg-icon-primary': totalOrder.isActive,
                }"
              >
                <inline-svg
                  v-if="totalOrder.query === 'asc'"
                  src="media/svg/icons/Navigation/Down-2.svg"
                />
                <inline-svg v-else src="media/svg/icons/Navigation/Up-2.svg" />
              </span>
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(rDevice, key) in data" :key="key + '_' + key">
          <td class="pl-6" :class="{ 'border-top-0': key === 0 }">
            <span class="text-dark-75 font-weight-bolder mb-1 font-size-lg">{{
              rDevice.name
            }}</span>
            <div>
              <span
                v-for="(tag, key) in rDevice.tags"
                :key="key"
                class="label label-sm label-light-primary label-inline mr-2"
                >{{ tag }}</span
              >
            </div>
            <div class="mt-1">{{ rDevice.imei }}</div>
          </td>
          <td class="text-right" :class="{ 'border-top-0': key === 0 }">
            {{ rDevice.location }}
          </td>
          <td
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="text-right"
            :class="{ 'border-top-0': key === 0 }"
          >
            {{ rDevice.lessor }}
          </td>
          <td
            v-if="currentUserCompanyModule[0] === 'gaming'"
            :class="{ 'border-top-0': key === 0 }"
            class="text-right"
          >
            <span v-if="rDevice.netCoin !== 0"
              >{{ rDevice.netCoin | currencyFormatKn }}
            </span>
            <span v-else>0 €</span>
          </td>
          <td
            v-if="currentUserCompanyModule[0] === 'gaming'"
            :class="{ 'border-top-0': key === 0 }"
            class="text-right"
          >
            <span v-if="rDevice.coin !== 0"
              >{{ rDevice.coin | currencyFormatKn }}
            </span>
            <span v-else>0 €</span>
          </td>
          <td
            v-if="currentUserCompanyModule[0] === 'gaming'"
            :class="{ 'border-top-0': key === 0 }"
            class="text-right"
          >
            <span v-if="rDevice.netSms !== 0"
              >{{ rDevice.netSms | currencyFormatKn }}
            </span>
            <span v-else>0 €</span>
          </td>
          <td
            v-if="currentUserCompanyModule[0] === 'gaming'"
            :class="{ 'border-top-0': key === 0 }"
            class="text-right"
          >
            <span v-if="rDevice.sms !== 0"
              >{{ rDevice.sms | currencyFormatKn }}
            </span>
            <span v-else>0 €</span>
          </td>
          <td
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="text-right"
            :class="{ 'border-top-0': key === 0 }"
          >
            <span v-if="rDevice.net !== 0"
              >{{ rDevice.net | currencyFormatKn }}
            </span>
            <span v-else>0 €</span>
          </td>
          <td
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="text-right"
            :class="{ 'border-top-0': key === 0 }"
          >
            <span v-if="rDevice.pdv !== 0"
              >{{ rDevice.pdv | currencyFormatKn }}
            </span>
            <span v-else>0 €</span>
          </td>
          <td
            class="text-dark-75 font-weight-bolder text-right"
            :class="{ 'border-top-0': key === 0 }"
          >
            <span v-if="rDevice.oldAmount !== 0"
              >{{ rDevice.oldAmount | currencyFormatKn }}
            </span>
            <span v-else>0 €</span>
          </td>
          <td
            v-if="currentUserCompanyModule[0] === 'gaming'"
            :class="{ 'border-top-0': key === 0 }"
            class="text-dark-75 font-weight-bolder text-right"
          >
            <span v-if="rDevice.loyalty !== 0"
              >{{ rDevice.loyalty | currencyFormatKn }}
            </span>
            <span v-else>0 €</span>
          </td>
          <td
            v-if="currentUserCompanyModule[0] === 'gaming'"
            :class="{ 'border-top-0': key === 0 }"
            class="text-dark-75 font-weight-bolder font-italic text-right pr-6"
          >
            <span v-if="rDevice.total !== 0"
              >{{ rDevice.total | currencyFormatKn }}
            </span>
            <span v-else>0 €</span>
          </td>
        </tr>
        <tr>
          <td class="pt-10 pl-6 font-size-lg font-weight-bolder text-uppercase">
            {{ $t('GENERAL.TOTAL') }}:
          </td>
          <td></td>
          <td v-if="currentUserCompanyModule[0] === 'gaming'"></td>
          <td
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase"
          >
            <span v-if="generateReportNetCoin !== 0"
              >{{ generateReportNetCoin | currencyFormatKn }}
            </span>
            <span v-else>0</span>
          </td>
          <td
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase"
          >
            <span v-if="generateReportCoin !== 0"
              >{{ generateReportCoin | currencyFormatKn }}
            </span>
            <span v-else>0</span>
          </td>
          <td
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase"
          >
            <span v-if="generateReportNetSms !== 0">{{
              generateReportNetSms | currencyFormatKn
            }}</span>
            <span v-else>0</span>
          </td>
          <td
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase"
          >
            <span v-if="generateReportSms !== 0">{{
              generateReportSms | currencyFormatKn
            }}</span>
            <span v-else>0</span>
          </td>
          <td
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase"
          >
            {{ generateReportNet | currencyFormatKn }}
          </td>
          <td
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase"
          >
            {{ generateReportPdv | currencyFormatKn }}
          </td>
          <td
            class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase pr-6"
          >
            {{ generateReportAmount | currencyFormatKn }}
          </td>
          <td
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase pr-6"
          >
            <span v-if="generateReportLoyalty !== 0">{{
              generateReportLoyalty | currencyFormatKn
            }}</span>
            <span v-else>0</span>
          </td>
          <td
            v-if="currentUserCompanyModule[0] === 'gaming'"
            class="pt-10 font-size-lg text-right font-weight-bolder text-uppercase pr-6"
          >
            <span v-if="generateReportTotal !== 0">{{
              generateReportTotal | currencyFormatKn
            }}</span>
            <span v-else>0</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DeviceReportTableKn',
  props: {
    traffic: { type: Array },
  },
  computed: {
    ...mapGetters(['currentUserCompanyOib', 'currentUserCompanyModule']),
  },
  data() {
    return {
      data: [],
      generateReportNetCoin: 0,
      generateReportCoin: 0,
      generateReportNetSms: 0,
      generateReportSms: 0,
      generateReportNet: 0,
      generateReportPdv: 0,
      generateReportAmount: 0,
      generateReportLoyalty: 0,
      generateReportTotal: 0,
      deviceNameOrder: { query: 'asc', isActive: false },
      coinOrder: { query: 'asc', isActive: false },
      smsOrder: { query: 'asc', isActive: false },
      cSOrder: { query: 'asc', isActive: false },
      loyaltyOrder: { query: 'asc', isActive: false },
      totalOrder: { query: 'asc', isActive: false },
      isGenerated: false,
    }
  },
  mounted() {
    this.traffic.forEach((transaction) => {
      let netCoin = 0
      if (
        transaction.paymentType === 'pay_coin' ||
        transaction.paymentType === 'pay_cctalk' ||
        transaction.paymentType === 'pay_mdb' ||
        transaction.paymentType === 'pay_sci'
      ) {
        netCoin = parseFloat(transaction.oldAmount * 0.8)
        this.generateReportNetCoin += netCoin
      }

      let coin = 0
      if (
        transaction.paymentType === 'pay_coin' ||
        transaction.paymentType === 'pay_cctalk' ||
        transaction.paymentType === 'pay_mdb' ||
        transaction.paymentType === 'pay_sci'
      ) {
        coin = parseFloat(transaction.oldAmount)
        this.generateReportCoin += coin
      }

      let netSms = 0
      if (transaction.paymentType === 'pay_sms') {
        netSms = parseFloat(transaction.oldAmount * 0.8)
        this.generateReportNetSms += netSms
      }

      let sms = 0
      if (transaction.paymentType === 'pay_sms') {
        sms = parseFloat(transaction.oldAmount)
        this.generateReportSms += sms
      }

      let net = 0
      if (
        transaction.paymentType === 'pay_coin' ||
        transaction.paymentType === 'pay_cctalk' ||
        transaction.paymentType === 'pay_mdb' ||
        transaction.paymentType === 'pay_sci' ||
        transaction.paymentType === 'pay_sms'
      ) {
        net = parseFloat(transaction.oldAmount) * 0.8
        this.generateReportNet += net
      }

      let pdv = net * 0.25
      this.generateReportPdv += pdv

      let oldAmount = 0
      if (
        transaction.paymentType === 'pay_coin' ||
        transaction.paymentType === 'pay_cctalk' ||
        transaction.paymentType === 'pay_mdb' ||
        transaction.paymentType === 'pay_sci' ||
        transaction.paymentType === 'pay_sms'
      ) {
        oldAmount = parseFloat(transaction.oldAmount)
        this.generateReportAmount += oldAmount
      }

      let loyalty = 0
      if (transaction.paymentType === 'pay_rfcard') {
        loyalty = parseFloat(transaction.oldAmount)
        this.generateReportLoyalty += loyalty
      }

      let total = parseFloat(transaction.oldAmount)
      this.generateReportTotal += total

      const row = this.data.findIndex(
        (device) => device.imei === transaction.imei
      )
      const col = this.data[row]

      if (col === undefined) {
        this.data.push({
          name: transaction.deviceName,
          imei: transaction.imei,
          location: transaction.location,
          address: transaction.address,
          lessor: transaction.lessor,
          tags: transaction.tags,
          paymentType: transaction.paymentType,
          netCoin: netCoin,
          coin: coin,
          netSms: netSms,
          sms: sms,
          net: net,
          pdv: pdv,
          oldAmount: oldAmount,
          loyalty: loyalty,
          total: total,
        })
      } else {
        this.data[row].netCoin += netCoin
        this.data[row].coin += coin
        this.data[row].netSms += netSms
        this.data[row].sms += sms
        this.data[row].net += net
        this.data[row].pdv += pdv
        this.data[row].oldAmount += oldAmount
        this.data[row].loyalty += loyalty
        this.data[row].total += total
      }
    })

    setTimeout(() => {
      this.isGenerated = true
    }, 1000)
  },
  methods: {
    orderByDeviceName() {
      this.clearActiveFilters()
      this.deviceNameOrder.isActive = true

      if (this.deviceNameOrder.query === 'asc') {
        this.deviceNameOrder.query = 'desc'
        this.data.sort((a, b) => {
          if (a.name < b.name) return -1
          if (a.name > b.name) return 1
          return 0
        })
      } else {
        this.deviceNameOrder.query = 'asc'
        this.data.sort((a, b) => {
          if (a.name > b.name) return -1
          if (a.name > b.name) return 1
          return 0
        })
      }
    },
    orderByCoin() {
      this.clearActiveFilters()
      this.coinOrder.isActive = true

      if (this.coinOrder.query === 'asc') {
        this.coinOrder.query = 'desc'
        this.data.sort((a, b) => a.coin - b.coin)
      } else {
        this.coinOrder.query = 'asc'
        this.data.sort((a, b) => b.coin - a.coin)
      }
    },
    orderBySms() {
      this.clearActiveFilters()
      this.smsOrder.isActive = true

      if (this.smsOrder.query === 'asc') {
        this.smsOrder.query = 'desc'
        this.data.sort((a, b) => a.sms - b.sms)
      } else {
        this.smsOrder.query = 'asc'
        this.data.sort((a, b) => b.sms - a.sms)
      }
    },
    orderByCs() {
      this.clearActiveFilters()
      this.cSOrder.isActive = true

      if (this.cSOrder.query === 'asc') {
        this.cSOrder.query = 'desc'
        this.data.sort((a, b) => a.oldAmount - b.oldAmount)
      } else {
        this.cSOrder.query = 'asc'
        this.data.sort((a, b) => b.oldAmount - a.oldAmount)
      }
    },
    orderByLoyalty() {
      this.clearActiveFilters()
      this.loyaltyOrder.isActive = true

      if (this.loyaltyOrder.query === 'asc') {
        this.loyaltyOrder.query = 'desc'
        this.data.sort((a, b) => a.loyalty - b.loyalty)
      } else {
        this.loyaltyOrder.query = 'asc'
        this.data.sort((a, b) => b.loyalty - a.loyalty)
      }
    },
    orderByTotal() {
      this.clearActiveFilters()
      this.totalOrder.isActive = true

      if (this.totalOrder.query === 'asc') {
        this.totalOrder.query = 'desc'
        this.data.sort((a, b) => a.total - b.total)
      } else {
        this.totalOrder.query = 'asc'
        this.data.sort((a, b) => b.total - a.total)
      }
    },
    clearActiveFilters() {
      this.deviceNameOrder.isActive = false
      this.coinOrder.isActive = false
      this.smsOrder.isActive = false
      this.cSOrder.isActive = false
      this.loyaltyOrder.isActive = false
      this.totalOrder.isActive = false
    },
  },
}
</script>
