<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header border-0 pb-0">
        <h3 class="card-title">
          {{ $t('GENERAL.TRAFFIC_FOR_THE_LAST_30_DAYS') }}
        </h3>
      </div>
      <div class="card-body px-0 py-0">
        <VueApexCharts
          v-if="isReady"
          :options="options"
          :series="series"
        ></VueApexCharts>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import format from 'date-fns/format'
import { getDayNameFromDate } from '@/core/helpers/helpers'

export default {
  name: 'DaysInMontyTraffic',
  components: {
    VueApexCharts,
  },
  props: {
    todayTraffic: {
      type: Number,
    },
    traffic: {
      type: Array,
    },
  },
  data() {
    return {
      days: [],
      options: {
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            dataLabels: {
              orientation: 'vertical',
              position: 'bottom',
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -2,
          offsetY: 7,
          formatter(val) {
            if (val !== null) {
              return val
                .toFixed(2)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
            }
            return val
          },
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
            rotate: -90,
            minHeight: 0,
            maxHeight: 300,
            style: {
              colors: [],
              fontSize: '12px',
              fontWeight: 400,
            },
            offsetX: 0,
            offsetY: 0,
            formatter: (value) => {
              return value
            },
          },
        },
        yaxis: {
          show: true,
          labels: {
            show: true,
            formatter(val) {
              if (val !== null) {
                return (
                  val
                    .toFixed(2)
                    .replace('.', ',')
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
                )
              }
              return val
            },
          },
        },
        grid: {
          show: false,
        },
        legend: {
          show: false,
        },
        title: {},
        colors: [
          function ({ dataPointIndex }) {
            if (dataPointIndex === 0) return '#F64E60'
            else if (dataPointIndex === 31) return '#989898'
            else return '#269ffb'
          },
        ],
      },
      series: [
        {
          name: 'Trafic',
          data: [],
        },
      ],
      isReady: false,
    }
  },
  mounted() {
    this.options.xaxis.categories.push(
      `${this.getDayNameFromDate(new Date())}: ${format(
        new Date(),
        'dd.MM.yyyy'
      )}`
    )
    this.series[0].data.push(this.todayTraffic)

    const mq = window.matchMedia('(min-width: 480px)')

    if (!mq.matches) {
      this.options.chart.type = 'area'
      this.options.xaxis.labels.show = false
      this.options.yaxis.show = false
    }

    // this.traffic.reverse()
    this.traffic.forEach((transaction) => {
      this.days.push({
        date: transaction.date,
        amount: transaction.amount,
      })

      this.options.xaxis.categories.push(
        `${this.getDayNameFromDate(new Date(transaction.date))}: ${format(
          new Date(transaction.date),
          'dd.MM.yyyy'
        )}`
      )
      this.series[0].data.push(transaction.amount)
    })

    // Average
    let averageFull = 0
    this.series[0].data.forEach((day, key) => {
      if (key !== 0) averageFull += day
    })
    this.series[0].data.push((averageFull / 30).toFixed(2))
    this.options.xaxis.categories.push('Average')

    this.isReady = true
  },
  methods: {
    getDayNameFromDate(date) {
      const day = getDayNameFromDate(date, this.$i18n.locale)
      return day.charAt(0).toUpperCase() + day.slice(1)
    },
  },
}
</script>
