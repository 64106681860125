<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header border-0">
            <div class="card-title">
              <h3 class="card-label">Unfiscal Bills</h3>
            </div>
          </div>
          <div class="card-body py-0">
            <table
              class="table table-head-custom table-vertical-center table-head-bg"
            >
              <thead>
                <tr class="text-uppercase">
                  <th class="pl-6"><span>{{ $t('MAIN_MENU.COMPANY') }}</span></th>
                  <th><span>Imei</span></th>
                  <th><span>Business space label</span></th>
                  <th><span>{{ $t('GENERAL.AMOUNT') }}</span></th>
                  <th class="text-right pr-6"><span>{{ $t('GENERAL.DATE_AND_TIME') }}</span></th>
                </tr>
              </thead>
              <tbody v-for="(company, key) in companies" :key="key">
                <tr v-for="(bill, billKey) in company.bills" :key="billKey">
                  <td class="pl-6" :class="{ 'border-top-0': billKey === 0 }">
                    <span
                      class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                    >
                      {{ bill.meta.companyName }}
                    </span>
                  </td>
                  <td :class="{ 'border-top-0': billKey === 0 }">
                    <span>{{ bill.imei }}</span>
                  </td>
                  <td :class="{ 'border-top-0': billKey === 0 }">
                    <span>{{ bill.meta.business_space_label }}</span>
                  </td>
                  <td :class="{ 'border-top-0': billKey === 0 }">
                    <span>{{ parseFloat(bill.amount) | currencyFormat }}</span>
                  </td>
                  <td
                    class="text-right pr-6"
                    :class="{ 'border-top-0': billKey === 0 }"
                  >
                    <span>{{ bill.createdAt | formatDateTime }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card-footer px-15 py-6">
            <div class="d-flex justify-content-between">
              <span class="font-weight-bolder text-dark">{{ $t('GENERAL.TOTAL') }}:</span>
              <span class="font-weight-bolder text-dark">{{
                totalUnFiscalizedBills
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '../../../../core/services/api.service'
import axios from 'axios'

export default {
  name: 'UnFiscalBills',
  data() {
    return {
      companies: [],
      bills: [],
      totalUnFiscalizedBills: 0
    }
  },
  mounted() {
    ApiService.get(
      'companies',
      '?properties[]=id&properties[]=name&properties[]=oib&pagination=false'
    ).then(({ data }) => {
      data['hydra:member'].forEach(company => {
        axios
          .get(
            `${process.env.VUE_APP_BILLS_API_URL}/api/v1/bills?oib=${company.oib}&isFiscal=false&order[createdAt]=desc&pagination=false`
          )
          .then(({ data }) => {
            if (data['hydra:member'].length > 0) {
              this.companies.push({
                oib: company.oib,
                bills: data['hydra:member']
              })
              this.totalUnFiscalizedBills += data['hydra:member'].length
            }
          })
      })
    })
  },
  methods: {}
}
</script>
