<template>
  <div
    class="card card-custom bg-radial-gradient-primary gutter-b card-stretch animate__animated animate__fadeIn"
  >
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title font-weight-bolder text-white">
        {{ $t('GENERAL.DAILY_INDEX') }}
      </h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body d-flex flex-column p-0" style="position: relative">
      <!--begin::Chart-->
      <VueApexCharts
        v-if="isReady"
        type="area"
        :options="chartOptions"
        :series="series"
      ></VueApexCharts>
      <!--end::Chart-->

      <!--begin::Stats-->
      <div class="card-spacer bg-white rounded-bottom flex-grow-1">
        <div class="row m-0">
          <div class="col px-4 py-6 mr-4">
            <div class="font-size-sm text-muted font-weight-bold">
              <span>{{ $t('GENERAL.DAILY_INDEX') }}</span>
              <span v-b-tooltip.hover.right="$t('GENERAL.CUMULATIVE')"
                ><i class="la la-info-circle ml-1"></i
              ></span>
            </div>
            <span class="font-weight-boldest fs-2x pt-1">
              {{
                (
                  ((todayTotal || 0) / (todayMinusSevenDaysTotal || 0) - 1) *
                  100
                ).toFixed(2)
              }}
              %</span
            >
            <div class="font-size-h4 font-weight-bolder" v-if="todayTotal > 0">
              {{ todayTotal | currencyFormat }}/{{
                todayMinusSevenDaysTotal | currencyFormat
              }}
            </div>
          </div>
          <div class="row text-right px-4 py-6">
            <div
              @click="goToTodayRevenue"
              class="symbol symbol-40 symbol-light-primary mt-6 mr-2"
            >
              <span class="symbol-label cursor-pointer">
                <span class="svg-icon menu-icon svg-icon-primary">
                  <inline-svg src="media/svg/icons/Shopping/Money.svg" />
                </span>
              </span>
            </div>
            <div
              @click="showDailyIndexHistory"
              class="symbol symbol-40 symbol-light-primary mt-6"
            >
              <span class="symbol-label cursor-pointer" v-b-tooltip.hover.top="$t('DASHBOARD.SHOW_GRAPHS')">
                <span class="svg-icon svg-icon-lx svg-icon-primary">
                  <inline-svg src="media/svg/icons/Media/Equalizer.svg" />
                </span>
              </span>
            </div>
          </div>
        </div>
        <div class="row m-0">
          <div class="col px-4 py-6 mr-4">
            <div class="font-size-sm text-muted font-weight-bold">
              <span>{{ $t('GENERAL.LAST_YEAR_DAILY_INDEX') }}</span>
              <span v-b-tooltip.hover.right="$t('GENERAL.CUMULATIVE')"
                ><i class="la la-info-circle ml-1"></i
              ></span>
            </div>
            <span class="font-weight-boldest fs-2x pt-1">
              {{
                (((todayTotal || 0) / todayLastYearTotal - 1) * 100).toFixed(2)
              }}%
            </span>
            <div class="font-size-h4 font-weight-bolder">
              {{ (todayTotal || 0) | currencyFormat }}/{{
                todayLastYearTotal | currencyFormat
              }}
            </div>
          </div>
        </div>
      </div>
      <!--end::Stats-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import getHours from 'date-fns/getHours'

export default {
  name: 'DailyIndexWidget',
  components: {
    VueApexCharts,
  },
  props: {
    todayTotal: {
      type: Number,
    },
    todayTransactions: {
      type: Array,
    },
    todayMinusSevenDaysTotal: {
      type: Number,
    },
    todayMinusSevenDaysTransactions: {
      type: Array,
    },
    todayLastYearTotal: {
      type: Number,
    },
    todayLastYearTransactions: {
      type: Array,
    },
    isChecked: {
      type: Boolean,
    },
  },
  data() {
    const hours = {}
    for (let i = 0; i < 24; i++) {
      const key = `hour${i}`
      hours[key] = {
        today: { name: key, total: 0 },
        todayMinus7Days: { name: key, total: 0 },
      }
    }
    return {
      series: [
        {
          name: '-7 days',
          data: [],
        },
        {
          name: 'Today',
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: 'area',
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: true,
          },
          parentHeightOffset: 0,
        },
        tooltip: {
          enabled: true,
          shared: true,
          marker: {
            show: true,
          },
          inverseOrder: true,
          followCursor: true,
          x: {
            show: true,
          },
          y: {
            show: false,
            formatter(val) {
              return (
                val
                  .toFixed(2)
                  .replace('.', ',')
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
              )
            },
          },
        },
        colors: ['#dfdfdf', '#2ef50d'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          categories: [],
          labels: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          show: false,
        },
        grid: {
          show: false,
          padding: {
            left: 0,
            bottom: 0,
            right: 0,
          },
        },
        legend: {
          show: false,
        },
      },
      ...hours,
      transactions: [],
      transactions2: [],
      transactionOnexAxis: [],
      transactionTwoAxis: [],
      transactionAxis: [],
      isReady: false,
    }
  },
  mounted() {
    this.todayTransactions.forEach((transaction) => {
      if (getHours(new Date(transaction.createdAt)) < getHours(new Date())) {
        this.transactions.push({
          hour: getHours(new Date(transaction.createdAt)),
          amount: transaction.amount,
          paymentType: transaction.paymentType,
          date: new Date(transaction.createdAt),
        })
      }
    })

    this.todayMinusSevenDaysTransactions.forEach((transaction) => {
      if (getHours(new Date(transaction.createdAt)) < getHours(new Date())) {
        this.transactions2.push({
          hour: getHours(new Date(transaction.createdAt)),
          amount: transaction.amount,
          paymentType: transaction.paymentType,
          date: new Date(transaction.createdAt),
        })
      }
    })

    // 1
    this.transactions.forEach((transaction) => {
      if (this.transactionAxis.indexOf(transaction.hour) === -1) {
        this.transactionAxis.push(transaction.hour)
      }

      this[`hour${transaction.hour}`].today.total += parseFloat(
        transaction.amount
      )
    })

    // 2
    this.transactions2.forEach((transaction) => {
      if (this.transactionAxis.indexOf(transaction.hour) === -1) {
        this.transactionAxis.push(transaction.hour)
      }

      this[`hour${transaction.hour}`].todayMinus7Days.total += parseFloat(
        transaction.amount
      )
    })

    const allH = this.transactionAxis.sort((a, b) => a - b)
    allH.forEach((h) => {
      this.chartOptions.xaxis.categories.push(h + 'h')

      this.series[0].data.push(this[`hour${h}`].todayMinus7Days.total)
      this.series[1].data.push(this[`hour${h}`].today.total)
    })

    setTimeout(() => {
      this.isReady = true
    }, 500)
  },
  methods: {
    goToTodayRevenue() {
      this.$router.push({
        path: '/today-revenue/list',
        query: {
          sortBy: 'total',
          order: 'desc',
        },
      })
    },
    showDailyIndexHistory() {
      this.$emit('showDailyIndexHistory')
    },
    toggleDailyIndexHybrid() {
      this.$emit('toggleDailyIndexHybrid')
    },
  },
}
</script>
