<template>
  <div
    class="card card-custom bg-radial-gradient-primary gutter-b card-stretch animate__animated animate__fadeIn"
  >
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title font-weight-bolder text-white">
        {{ $t('GENERAL.MONTHLY_INDEX') }}
      </h3>
      <div class="card-toolbar">
        <span
          v-if="currentUserCompanyModule[0] === 'gaming'"
          class="switch switch-dark switch-sm switch-icon"
        >
          <label  v-b-tooltip.hover.top="$t('DASHBOARD.ALIGN_WEEKENDS')">
            <input
              @click="toggleMonthlyIndexHybrid"
              type="checkbox"
              :checked="isChecked"
              name="select"
            />
            <span></span>
          </label>
        </span>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body d-flex flex-column p-0" style="position: relative">
      <!--begin::Chart-->
      <VueApexCharts
        v-if="isReady"
        type="area"
        :options="chartOptions"
        :series="series"
      ></VueApexCharts>
      <!--end::Chart-->

      <!--begin::Stats-->
      <div class="card-spacer bg-white rounded-bottom flex-grow-1">
        <div class="row m-0">
          <div class="col px-4 py-6 mr-6">
            <div class="font-size-sm text-muted font-weight-bold">
              <span>{{ $t('GENERAL.MONTHLY_INDEX') }}</span>
              <span v-b-tooltip.hover.right="$t('GENERAL.CUMULATIVE')"
                ><i class="la la-info-circle ml-1"></i
              ></span>
            </div>
            <span class="font-weight-boldest fs-2x pt-1"
              >{{ ((currentMonth / prevMonth - 1) * 100).toFixed(2) }} %</span
            >
            <div class="font-size-h4 font-weight-bolder">
              {{ currentMonth | currencyFormat }}/{{
                prevMonth | currencyFormat
              }}
            </div>
          </div>
          <div class="col text-right px-4 py-6">
            <div
              @click="showMonthlyIndexHistory"
              class="symbol symbol-40 symbol-light-primary mt-6"
            >
              <span class="symbol-label cursor-pointer">
                <span class="svg-icon svg-icon-lx svg-icon-primary"  v-b-tooltip.hover.top="$t('DASHBOARD.SHOW_GRAPHS')">
                  <inline-svg src="media/svg/icons/Media/Equalizer.svg" />
                </span>
              </span>
            </div>
          </div>
        </div>
        <div class="row m-0">
          <div class="col px-4 py-6 mr-6">
            <div class="font-size-sm text-muted font-weight-bold">
              <span>{{ $t('GENERAL.LAST_YEAR_MONTHLY_INDEX') }}</span>
              <span v-b-tooltip.hover.right="$t('GENERAL.CUMULATIVE')"
                ><i class="la la-info-circle ml-1"></i
              ></span>
            </div>
            <span class="font-weight-boldest fs-2x pt-1">
              {{ ((currentMonth / thisMonthLastYear - 1) * 100).toFixed(2) }}
              %
            </span>
            <div class="font-size-h4 font-weight-bolder">
              {{ currentMonth | currencyFormat }}/{{
                thisMonthLastYear | currencyFormat
              }}
            </div>
          </div>
        </div>
      </div>
      <!--end::Stats-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import format from 'date-fns/format'
import getWeek from 'date-fns/getWeek'
import { mapGetters } from 'vuex'
import { isToday } from 'date-fns'

export default {
  name: 'MonthlyIndexWidget',
  components: {
    VueApexCharts,
  },
  props: {
    thisMonthTotal: {
      type: Number,
    },
    thisMonthTransactions: {
      type: Array,
    },
    lastMonthTotal: {
      type: Number,
    },
    lastMonthTransactions: {
      type: Array,
    },
    todayTotal: {
      type: Number,
    },
    todayMinusSevenDaysTotal: {
      type: Number,
    },
    thisMonthLastYear: {
      type: Number,
    },
    isChecked: {
      type: Boolean,
    },
  },
  data() {
    return {
      series: [
        {
          name: 'Last Month',
          data: [],
        },
        {
          name: 'This Month',
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: 'area',
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: true,
          },
          parentHeightOffset: 0,
        },
        tooltip: {
          enabled: true,
          shared: true,
          inverseOrder: true,
          x: {
            show: true,
          },
          y: {
            show: false,
            formatter(val) {
              return (
                val
                  .toFixed(2)
                  .replace('.', ',')
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
              )
            },
          },
          marker: {
            show: true,
          },
        },
        colors: ['#dfdfdf', '#2ef50d'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          categories: [],
          labels: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          show: false,
        },
        grid: {
          show: false,
          padding: {
            left: 0,
            bottom: 0,
            right: 0,
          },
        },
        legend: {
          show: false,
        },
      },
      currentMonth: 0,
      prevMonth: 0,
      currentMonthTransactions: [],
      prevMonthTransactions: [],
      currentMonthDays: [],
      prevMonthDays: [],

      transactions: [],
      transactions2: [],
      transactionOnexAxis: [],
      transactionTwoAxis: [],
      transactionAxis: [],
      hour0: {
        today: { name: 'hour0', total: 0 },
        todayMinus7Days: { name: 'hour0', total: 0 },
      },
      hour1: {
        today: { name: 'hour1', total: 0 },
        todayMinus7Days: { name: 'hour1', total: 0 },
      },
      hour2: {
        today: { name: 'hour2', total: 0 },
        todayMinus7Days: { name: 'hour2', total: 0 },
      },
      hour3: {
        today: { name: 'hour3', total: 0 },
        todayMinus7Days: { name: 'hour3', total: 0 },
      },
      hour4: {
        today: { name: 'hour4', total: 0 },
        todayMinus7Days: { name: 'hour4', total: 0 },
      },
      hour5: {
        today: { name: 'hour5', total: 0 },
        todayMinus7Days: { name: 'hour5', total: 0 },
      },
      hour6: {
        today: { name: 'hour6', total: 0 },
        todayMinus7Days: { name: 'hour6', total: 0 },
      },
      hour7: {
        today: { name: 'hour7', total: 0 },
        todayMinus7Days: { name: 'hour7', total: 0 },
      },
      hour8: {
        today: { name: 'hour8', total: 0 },
        todayMinus7Days: { name: 'hour8', total: 0 },
      },
      hour9: {
        today: { name: 'hour9', total: 0 },
        todayMinus7Days: { name: 'hour9', total: 0 },
      },
      hour10: {
        today: { name: 'hour10', total: 0 },
        todayMinus7Days: { name: 'hour10', total: 0 },
      },
      hour11: {
        today: { name: 'hour11', total: 0 },
        todayMinus7Days: { name: 'hour11', total: 0 },
      },
      hour12: {
        today: { name: 'hour12', total: 0 },
        todayMinus7Days: { name: 'hour12', total: 0 },
      },
      hour13: {
        today: { name: 'hour13', total: 0 },
        todayMinus7Days: { name: 'hour13', total: 0 },
      },
      hour14: {
        today: { name: 'hour14', total: 0 },
        todayMinus7Days: { name: 'hour14', total: 0 },
      },
      hour15: {
        today: { name: 'hour15', total: 0 },
        todayMinus7Days: { name: 'hour15', total: 0 },
      },
      hour16: {
        today: { name: 'hour16', total: 0 },
        todayMinus7Days: { name: 'hour16', total: 0 },
      },
      hour17: {
        today: { name: 'hour17', total: 0 },
        todayMinus7Days: { name: 'hour17', total: 0 },
      },
      hour18: {
        today: { name: 'hour18', total: 0 },
        todayMinus7Days: { name: 'hour18', total: 0 },
      },
      hour19: {
        today: { name: 'hour19', total: 0 },
        todayMinus7Days: { name: 'hour19', total: 0 },
      },
      hour20: {
        today: { name: 'hour20', total: 0 },
        todayMinus7Days: { name: 'hour20', total: 0 },
      },
      hour21: {
        today: { name: 'hour21', total: 0 },
        todayMinus7Days: { name: 'hour21', total: 0 },
      },
      hour22: {
        today: { name: 'hour22', total: 0 },
        todayMinus7Days: { name: 'hour22', total: 0 },
      },
      hour23: {
        today: { name: 'hour23', total: 0 },
        todayMinus7Days: { name: 'hour23', total: 0 },
      },
      isReady: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserCompanyModule']),
  },
  mounted() {
    this.currentMonth = this.thisMonthTotal

    // error todayMinusSevenDaysTotal
    this.prevMonth = this.lastMonthTotal

    this.thisMonthTransactions.forEach((transaction) => {
      this.currentMonthDays.push({
        day: new Date(transaction.date),
        week: getWeek(new Date(transaction.date)),
        amount: transaction.total,
        paymentType: transaction.paymentType,
        date: new Date(transaction.date),
      })
    })

    // current month
    this.currentMonthDays.push({
      week: getWeek(new Date()),
      amount: this.todayTotal,
      paymentType: null,
      date: new Date(),
    })

    this.currentMonthDays = this.currentMonthDays.sort(function (a, b) {
      return new Date(a.date) - new Date(b.date)
    })

    this.currentMonthDays.forEach((day) => {
      if (!isToday(day.date)) {
        this.chartOptions.xaxis.categories.push(
          format(new Date(day.date), 'dd.MM.yyyy')
        )
        this.series[1].data.push(day.amount)
      }
    })

    // last month
    this.lastMonthTransactions.forEach((transaction) => {
      this.prevMonthDays.push({
        day: new Date(transaction.date),
        week: getWeek(new Date(transaction.date)),
        amount: transaction.total,
        paymentType: transaction.paymentType,
        date: new Date(transaction.date),
      })
    })

    this.prevMonthDays.push({
      week: getWeek(new Date()),
      amount: this.todayMinusSevenDaysTotal,
      paymentType: null,
      date: new Date(),
    })

    this.prevMonthDays = this.prevMonthDays.sort(function (a, b) {
      return new Date(a.date) - new Date(b.date)
    })

    this.prevMonthDays.forEach((day, key) => {
      if (!isToday(day.date)) {
        this.chartOptions.xaxis.categories[key] =
          this.chartOptions.xaxis.categories[key] += ` - ${format(
            new Date(day.date),
            'dd.MM.yyyy'
          )}`

        this.series[0].data.push(day.amount)
      }
    })

    setTimeout(() => {
      this.isReady = true
    }, 500)
  },
  methods: {
    showMonthlyIndexHistory() {
      this.$emit('showMonthlyIndexHistory')
    },
    toggleMonthlyIndexHybrid() {
      this.$emit('toggleMonthlyIndexHybrid')
    },
  },
}
</script>
