<template>
  <div
    class="card card-custom bg-radial-gradient-primary gutter-b card-stretch animate__animated animate__fadeIn"
  >
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title font-weight-bolder text-white">
        {{ $t('GENERAL.YEARLY_INDEX') }}
      </h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body d-flex flex-column p-0" style="position: relative">
      <!--begin::Chart-->
      <VueApexCharts
        v-if="isReady"
        type="area"
        :options="chartOptions"
        :series="series"
      ></VueApexCharts>
      <!--end::Chart-->

      <!--begin::Stats-->
      <div class="card-spacer bg-white rounded-bottom flex-grow-1">
        <div class="row m-0">
          <div class="col px-4 py-6 mr-6">
            <div class="font-size-sm text-muted font-weight-bold">
              <span>{{ $t('GENERAL.YEARLY_INDEX') }}</span>
              <span v-b-tooltip.hover.right="$t('GENERAL.CUMULATIVE')">
                <i class="la la-info-circle ml-1"></i>
              </span>
            </div>
            <span class="font-weight-boldest fs-2x pt-1">
              {{ ((untilToday / untilTodayLastYear - 1) * 100).toFixed(2) }}
              %
            </span>
            <div class="font-size-h4 font-weight-bolder">
              <span v-if="untilToday > 0">{{
                untilToday | currencyFormat
              }}</span>
              <span v-else>0,00</span>
              <span>/</span>
              <span v-if="untilTodayLastYear > 0">{{
                untilTodayLastYear | currencyFormat
              }}</span>
              <span v-else>0,00</span>
            </div>
          </div>
          <div class="col text-right px-4 py-6"></div>
        </div>
        <div class="row m-0" style="height: 124px"></div>
      </div>
      <!--end::Stats-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import getWeek from 'date-fns/getWeek'
import format from 'date-fns/format'

export default {
  name: 'YearlyIndexWidget',
  components: {
    VueApexCharts,
  },
  props: {
    untilToday: {
      type: Number,
    },
    untilTodayTransactions: {
      type: Array,
    },
    todayToday: {
      type: Number,
    },
    untilTodayLastYear: {
      type: Number,
    },
    untilTodayLastYearTransactions: {
      type: Array,
    },
  },
  data() {
    return {
      series: [
        {
          name: '',
          data: [],
        },
        {
          name: '',
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: 'area',
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: true,
          },
          parentHeightOffset: 0,
        },
        tooltip: {
          enabled: true,
          shared: true,
          x: {
            show: true,
          },
          y: {
            show: false,
            formatter(val) {
              return (
                val
                  .toFixed(2)
                  .replace('.', ',')
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
              )
            },
          },
          marker: {
            show: true,
          },
        },
        colors: ['#dfdfdf', '#2ef50d'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          categories: [],
          labels: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          show: false,
        },
        grid: {
          show: false,
          padding: {
            left: 0,
            bottom: 0,
            right: 0,
          },
        },
        legend: {
          show: false,
        },
      },
      currentYearDays: [],
      prevYearDays: [],
      isReady: false,
    }
  },
  mounted() {
    //
    this.untilTodayTransactions.forEach((transaction) => {
      this.currentYearDays.push({
        day: new Date(transaction.date),
        week: getWeek(new Date(transaction.date)),
        amount: transaction.total,
        paymentType: transaction.paymentType,
        date: new Date(transaction.date),
      })
    })

    // this.currentYearDays.push({
    //   week: getWeek(new Date()),
    //   amount: this.todayTotal,
    //   paymentType: null,
    //   date: new Date()
    // })

    this.currentYearDays = this.currentYearDays.sort(function (a, b) {
      return new Date(a.date) - new Date(b.date)
    })

    this.currentYearDays.forEach((day) => {
      this.chartOptions.xaxis.categories.push(
        format(new Date(day.date), 'dd.MM.yyyy')
      )
      this.series[0].data.push(day.amount)
    })

    //
    this.untilTodayLastYearTransactions.forEach((transaction) => {
      this.prevYearDays.push({
        day: new Date(transaction.date),
        week: getWeek(new Date(transaction.date)),
        amount: transaction.total,
        paymentType: transaction.paymentType,
        date: new Date(transaction.date),
      })
    })

    this.prevYearDays = this.prevYearDays.sort(function (a, b) {
      return new Date(a.date) - new Date(b.date)
    })

    this.prevYearDays.forEach((day) => {
      this.chartOptions.xaxis.categories.push(
        format(new Date(day.date), 'dd.MM.yyyy')
      )
      this.series[1].data.push(day.amount)
    })

    setTimeout(() => {
      this.isReady = true
    }, 500)
  },
  methods: {
    showYearlyIndexHistory() {
      this.$emit('showYearlyIndexHistory')
    },
    toggleYearlyIndexHybrid() {
      this.$emit('toggleYearlyIndexHybrid')
    },
  },
}
</script>
