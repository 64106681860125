<template>
  <div>
    <div v-if="!isReady" class="col-12 d-flex align-items-center justify-content-center pb-5" style="height: 540px">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <div v-else class="card card-custom gutter-b px-6 py-3">
      <div class="card-header border-0 p-4">
        <h3 class="card-title mb-6">
          {{
            this.selectedOption === 'Best'
              ? $t('DEVICE_PERFORMANCE_GRAPH.BEST')
              : $t('DEVICE_PERFORMANCE_GRAPH.WORST')
          }}
          {{ $t('DEVICE_PERFORMANCE_GRAPH.PERFORMING_DEVICE') }}
        </h3>
        <select
          v-model="selectedOption"
          @change="updateIsWorst"
          class="form-control px-4"
        >
          <option value="Worst">
            {{ $t('DEVICE_PERFORMANCE_GRAPH.WORST') }}
            
          </option>
          <option value="Best">
            {{ $t('DEVICE_PERFORMANCE_GRAPH.BEST') }}
          </option>
        </select>
      </div>
      <div class="card-body px-2 py-4">
        <VueApexCharts
          :options="options"
          :series="series"
        ></VueApexCharts>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { mapGetters } from 'vuex'
import { format, addDays } from 'date-fns'
import ApiService from '@/core/services/api.service'
import { groupBy } from 'lodash'

export default {
  name: 'DevicePerformanceGraph',
  components: {
    VueApexCharts,
  },
  props: {},
  data() {
    return {
      options: {
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            dataLabels: {
              orientation: 'vertical',
              position: 'bottom',
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -2,
          offsetY: 7,
          formatter(val) {
            if (val !== null) {
              return val
                .toFixed(2)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
            }
            return val
          },
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
            rotate: -90,
            minHeight: 0,
            maxHeight: 300,
            style: {
              colors: [],
              fontSize: '12px',
              fontWeight: 400,
            },
            offsetX: 0,
            offsetY: 0,
            formatter: (value) => {
              return value
            },
          },
        },
        yaxis: {
          show: true,
          labels: {
            show: true,
            formatter(val) {
              if (val !== null) {
                return (
                  val
                    .toFixed(2)
                    .replace('.', ',')
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
                )
              }
              return val
            },
          },
        },
        grid: {
          show: false,
        },
        legend: {
          show: false,
        },
        title: {},
        colors: ['#269ffb'],
      },
      series: [
        {
          name: 'Traffic',
          data: [],
        },
      ],
      range: 10,
      sortedDevices: [],
      isReady: false,
      selectedOption: 'Best',
      numOfDevices: 10,
    }
  },
  computed: {
    ...mapGetters(['currentUserCompany', 'currentUserCompanyOib']),
  },
  mounted() {
    this.fetchAllTransactions().then((transactions) => {
      const filteredTransactions = transactions.filter(transaction => transaction.isSaldoReducer === 0);
      const groupedDevices = groupBy(filteredTransactions, 'deviceId')

      const amountPerDevice = Object.entries(groupedDevices).map(
        ([id, device]) => {
          return {
            deviceId: id,
            deviceName: device[0].deviceName,
            total: device.reduce(
              (total, transaction) => total + parseFloat(transaction.amount),
              0
            ),
          }
        }
      )

      this.sortedDevices = amountPerDevice.sort((a, b) => b.total - a.total)
      this.sortedDevices.slice(0, this.numOfDevices).forEach((device) => {
        this.options.xaxis.categories.push(device.deviceName)
        this.series[0].data.push(device.total)
      })

      setTimeout(() => {
        this.isReady = true
      }, 500)
    })
  },
  methods: {
    async fetchAllTransactions() {
      const todayMinus30Days = format(
        addDays(new Date(), -this.range),
        'yyyy-MM-dd'
      )
      let allDataFetched = false
      let page = 1
      const transactions = []

      while (!allDataFetched) {
        const { data } = await ApiService.get(
          'transactions',
          `?company=${
            this.currentUserCompany
          }&createdAt[after]=${todayMinus30Days}&pagination=true&itemsPerPage=${4000}&page=${page}`
        )

        if (
          !data ||
          !data['hydra:member'] ||
          data['hydra:member'].length === 0
        ) {
          allDataFetched = true
          break
        }

        transactions.push(...data['hydra:member'])

        if (data['hydra:member'].length < 200) {
          allDataFetched = true
        } else {
          page++
        }
      }
      return transactions
    },
    updateIsWorst() {
      this.isReady = false

      this.options.xaxis.categories = []
      this.series[0].data = []

      this.sortedDevices.reverse()
      this.sortedDevices.slice(0, this.numOfDevices).forEach((device) => {
        this.options.xaxis.categories.push(device.deviceName)
        this.series[0].data.push(device.total)
      })

      setTimeout(() => {
        this.isReady = true
      }, 500)
    },
  },
}
</script>
