<template>
  <div>
    <VueApexCharts
      v-if="isReady"
      :options="options"
      :series="series"
    ></VueApexCharts>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { mapGetters } from 'vuex'
import getWeek from 'date-fns/getWeek'

export default {
  name: 'WeeklyHistoryCumulative',
  components: {
    VueApexCharts,
  },
  props: {
    traffic: {
      type: Array,
    },
  },
  data() {
    return {
      options: {
        tooltip: {
          enabled: true,
          x: {
            show: true,
            formatter(val) {
              return val
            },
          },
          y: {
            formatter(val) {
              return (
                val
                  .toFixed(2)
                  .replace('.', ',')
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
              )
            },
            title: {
              formatter(seriesName) {
                return seriesName
              },
            },
          },
        },
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            dataLabels: {
              orientation: 'vertical',
              position: 'bottom',
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -2,
          offsetY: 7,
          formatter(val) {
            return (
              val
                .toFixed(2)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
            )
          },
        },
        grid: {
          show: true,
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
            rotate: -90,
          },
        },
        yaxis: {
          labels: {
            show: true,
            formatter(val) {
              return (
                val
                  .toFixed(2)
                  .replace('.', ',')
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
              )
            },
          },
        },
        legend: {
          show: false,
        },
        title: {},
        colors: [
          function ({ dataPointIndex }) {
            if (dataPointIndex === 0) return '#ef8585'
            else if (dataPointIndex === 34) return '#989898'
            else return '#269ffb'
          },
        ],
      },
      series: [
        {
          name: 'Pay',
          data: [],
        },
      ],
      transactions: [],
      weeks: [],
      today: null,
      isReady: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserCompanyOib']),
  },
  mounted() {
    this.traffic = this.traffic.sort(function (a, b) {
      return new Date(b.dayFormat) - new Date(a.dayFormat)
    })

    this.traffic.forEach((day) => {
      this.transactions.push({
        week: getWeek(day.dayFormat, { weekStartsOn: 1 }),
        amount: day.value,
      })
    })

    this.transactions.forEach((transaction) => {
      if (this.options.xaxis.categories.indexOf(transaction.week) === -1) {
        this.options.xaxis.categories.push(transaction.week)
        this.weeks.push(transaction.week)
      }
    })

    let totalL = 0
    this.options.xaxis.categories.forEach((week) => {
      let amount = 0

      this.transactions.forEach((transaction) => {
        if (transaction.week === week) {
          amount += parseFloat(transaction.amount)
          totalL += parseFloat(transaction.amount)
        }
      })

      this.series[0].data.push(amount)
    })

    let averageFinal = totalL / this.series[0].data.length

    // Average
    this.series[0].data.push(averageFinal)
    this.options.xaxis.categories.push('Average')

    // today
    // this.series[0].data[0] = this.thisWeekTotal

    setTimeout(() => {
      this.isReady = true
    }, 2000)
  },
}
</script>
