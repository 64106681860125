<template>
  <div
    class="card card-custom bg-radial-gradient-primary gutter-b card-stretch animate__animated animate__fadeIn"
  >
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title font-weight-bolder text-white">
        {{ $t('GENERAL.WEEKLY_INDEX') }}
      </h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body d-flex flex-column p-0" style="position: relative">
      <!--begin::Chart-->
      <VueApexCharts
        v-if="isReady"
        type="area"
        :options="chartOptions"
        :series="series"
      ></VueApexCharts>
      <!--end::Chart-->

      <!--begin::Stats-->
      <div class="card-spacer bg-white rounded-bottom flex-grow-1">
        <div class="row m-0">
          <div class="col px-4 py-6 mr-6">
            <div class="font-size-sm text-muted font-weight-bold">
              <span>{{ $t('GENERAL.WEEKLY_INDEX') }}</span>
              <span v-b-tooltip.hover.right="$t('GENERAL.CUMULATIVE')"
                ><i class="la la-info-circle ml-1"></i
              ></span>
            </div>
            <span class="font-weight-boldest fs-2x pt-1"
              >{{
                ((thisWeekTotal / lastWeekTotal - 1) * 100).toFixed(2)
              }}
              %</span
            >
            <div class="font-size-h4 font-weight-bolder">
              {{ thisWeekTotal | currencyFormat }}/{{
                lastWeekTotal | currencyFormat
              }}
            </div>
          </div>
          <div class="col text-right px-4 py-6">
            <div
              @click="showWeeklyIndexHistory"
              class="symbol symbol-40 symbol-light-primary mt-6"
            >
              <span class="symbol-label cursor-pointer"  v-b-tooltip.hover.top="$t('DASHBOARD.SHOW_GRAPHS')">
                <span class="svg-icon svg-icon-lx svg-icon-primary">
                  <inline-svg src="media/svg/icons/Media/Equalizer.svg" />
                </span>
              </span>
            </div>
          </div>
        </div>
        <div class="row m-0">
          <div class="col px-4 py-6 mr-6">
            <div class="font-size-sm text-muted font-weight-bold">
              <span>{{ $t('GENERAL.LAST_YEAR_WEEKLY_INDEX') }}</span>
              <span v-b-tooltip.hover.right="$t('GENERAL.CUMULATIVE')"
                ><i class="la la-info-circle ml-1"></i
              ></span>
            </div>
            <span class="font-weight-boldest fs-2x pt-1">
              {{
                ((thisWeekTotal / lastYearCurrentWeekTotal - 1) * 100).toFixed(
                  2
                )
              }}
              %
            </span>
            <div class="font-size-h4 font-weight-bolder">
              {{ thisWeekTotal | currencyFormat }}/{{
                lastYearCurrentWeekTotal | currencyFormat
              }}
            </div>
          </div>
        </div>
      </div>
      <!--end::Stats-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import isMonday from 'date-fns/isMonday'
import isTuesday from 'date-fns/isTuesday'
import isWednesday from 'date-fns/isWednesday'
import isThursday from 'date-fns/isThursday'
import isFriday from 'date-fns/isFriday'
import isSaturday from 'date-fns/isSaturday'
import isSunday from 'date-fns/isSunday'
import getWeek from 'date-fns/getWeek'
import getHours from 'date-fns/getHours'
import { format, isToday } from 'date-fns'
import { mapGetters } from 'vuex'

export default {
  name: 'WeeklyIndexWidget',
  components: {
    VueApexCharts,
  },
  props: {
    todayTotal: {
      type: Number,
    },
    thisWeekTotal: {
      type: Number,
    },
    thisWeekTransactions: {
      type: Array,
    },
    todayMinusSevenDaysTotal: {
      type: Number,
    },
    lastWeekTotal: {
      type: Number,
    },
    lastWeekTransactions: {
      type: Array,
    },
    todayTransactions: {
      type: Array,
    },
    todayMinusSevenDaysTransactions: {
      type: Array,
    },
    lastYearCurrentWeekTotal: {
      type: Number,
    },
  },
  data() {
    return {
      series: [
        {
          name: 'Last Week',
          data: [],
        },
        {
          name: 'This Week',
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: 'area',
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: true,
          },
          parentHeightOffset: 0,
        },
        tooltip: {
          enabled: true,
          shared: true,
          inverseOrder: true,
          x: {
            show: true,
          },
          y: {
            show: false,
            formatter(val) {
              return (
                val
                  .toFixed(2)
                  .replace('.', ',')
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
              )
            },
          },
          marker: {
            show: true,
          },
        },
        colors: ['#dfdfdf', '#2ef50d'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          categories: [],
          labels: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          show: false,
        },
        grid: {
          show: false,
          padding: {
            left: 0,
            bottom: 0,
            right: 0,
          },
        },
        legend: {
          show: false,
        },
      },
      thisWeekCumulative: 0,
      lastWeekCumulative: 0,
      thisWeekDays: [],
      lastWeekDays: [],
      transactions: [],
      transactions2: [],
      transactionOnexAxis: [],
      transactionTwoAxis: [],
      transactionAxis: [],
      hour0: {
        today: { name: 'hour0', total: 0 },
        todayMinus7Days: { name: 'hour0', total: 0 },
      },
      hour1: {
        today: { name: 'hour1', total: 0 },
        todayMinus7Days: { name: 'hour1', total: 0 },
      },
      hour2: {
        today: { name: 'hour2', total: 0 },
        todayMinus7Days: { name: 'hour2', total: 0 },
      },
      hour3: {
        today: { name: 'hour3', total: 0 },
        todayMinus7Days: { name: 'hour3', total: 0 },
      },
      hour4: {
        today: { name: 'hour4', total: 0 },
        todayMinus7Days: { name: 'hour4', total: 0 },
      },
      hour5: {
        today: { name: 'hour5', total: 0 },
        todayMinus7Days: { name: 'hour5', total: 0 },
      },
      hour6: {
        today: { name: 'hour6', total: 0 },
        todayMinus7Days: { name: 'hour6', total: 0 },
      },
      hour7: {
        today: { name: 'hour7', total: 0 },
        todayMinus7Days: { name: 'hour7', total: 0 },
      },
      hour8: {
        today: { name: 'hour8', total: 0 },
        todayMinus7Days: { name: 'hour8', total: 0 },
      },
      hour9: {
        today: { name: 'hour9', total: 0 },
        todayMinus7Days: { name: 'hour9', total: 0 },
      },
      hour10: {
        today: { name: 'hour10', total: 0 },
        todayMinus7Days: { name: 'hour10', total: 0 },
      },
      hour11: {
        today: { name: 'hour11', total: 0 },
        todayMinus7Days: { name: 'hour11', total: 0 },
      },
      hour12: {
        today: { name: 'hour12', total: 0 },
        todayMinus7Days: { name: 'hour12', total: 0 },
      },
      hour13: {
        today: { name: 'hour13', total: 0 },
        todayMinus7Days: { name: 'hour13', total: 0 },
      },
      hour14: {
        today: { name: 'hour14', total: 0 },
        todayMinus7Days: { name: 'hour14', total: 0 },
      },
      hour15: {
        today: { name: 'hour15', total: 0 },
        todayMinus7Days: { name: 'hour15', total: 0 },
      },
      hour16: {
        today: { name: 'hour16', total: 0 },
        todayMinus7Days: { name: 'hour16', total: 0 },
      },
      hour17: {
        today: { name: 'hour17', total: 0 },
        todayMinus7Days: { name: 'hour17', total: 0 },
      },
      hour18: {
        today: { name: 'hour18', total: 0 },
        todayMinus7Days: { name: 'hour18', total: 0 },
      },
      hour19: {
        today: { name: 'hour19', total: 0 },
        todayMinus7Days: { name: 'hour19', total: 0 },
      },
      hour20: {
        today: { name: 'hour20', total: 0 },
        todayMinus7Days: { name: 'hour20', total: 0 },
      },
      hour21: {
        today: { name: 'hour21', total: 0 },
        todayMinus7Days: { name: 'hour21', total: 0 },
      },
      hour22: {
        today: { name: 'hour22', total: 0 },
        todayMinus7Days: { name: 'hour22', total: 0 },
      },
      hour23: {
        today: { name: 'hour23', total: 0 },
        todayMinus7Days: { name: 'hour23', total: 0 },
      },
      isReady: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserCompanyOib']),
  },
  mounted() {
    if (isMonday(new Date())) {
      this.todayTransactions.forEach((transaction) => {
        this.transactions.push({
          hour: getHours(new Date(transaction.createdAt)),
          amount: transaction.amount,
          paymentType: transaction.paymentType,
          date: new Date(transaction.createdAt),
        })
      })

      this.todayMinusSevenDaysTransactions.forEach((transaction) => {
        this.transactions2.push({
          hour: getHours(new Date(transaction.createdAt)),
          amount: transaction.amount,
          paymentType: transaction.paymentType,
          date: new Date(transaction.createdAt),
        })
      })

      this.transactions.forEach((transaction) => {
        if (this.transactionAxis.indexOf(transaction.hour) === -1) {
          this.transactionAxis.push(transaction.hour)
        }

        if (transaction.hour === 0) {
          this.hour0.today.total += parseFloat(transaction.amount)
        } else if (transaction.hour === 1) {
          this.hour1.today.total += parseFloat(transaction.amount)
        } else if (transaction.hour === 2) {
          this.hour2.today.total += parseFloat(transaction.amount)
        } else if (transaction.hour === 3) {
          this.hour3.today.total += parseFloat(transaction.amount)
        } else if (transaction.hour === 4) {
          this.hour4.today.total += parseFloat(transaction.amount)
        } else if (transaction.hour === 5) {
          this.hour5.today.total += parseFloat(transaction.amount)
        } else if (transaction.hour === 6) {
          this.hour6.today.total += parseFloat(transaction.amount)
        } else if (transaction.hour === 7) {
          this.hour7.today.total += parseFloat(transaction.amount)
        } else if (transaction.hour === 8) {
          this.hour8.today.total += parseFloat(transaction.amount)
        } else if (transaction.hour === 9) {
          this.hour9.today.total += parseFloat(transaction.amount)
        } else if (transaction.hour === 10) {
          this.hour10.today.total += parseFloat(transaction.amount)
        } else if (transaction.hour === 11) {
          this.hour11.today.total += parseFloat(transaction.amount)
        } else if (transaction.hour === 12) {
          this.hour12.today.total += parseFloat(transaction.amount)
        } else if (transaction.hour === 13) {
          this.hour13.today.total += parseFloat(transaction.amount)
        } else if (transaction.hour === 14) {
          this.hour14.today.total += parseFloat(transaction.amount)
        } else if (transaction.hour === 15) {
          this.hour15.today.total += parseFloat(transaction.amount)
        } else if (transaction.hour === 16) {
          this.hour16.today.total += parseFloat(transaction.amount)
        } else if (transaction.hour === 17) {
          this.hour17.today.total += parseInt(transaction.amount)
        } else if (transaction.hour === 18) {
          this.hour18.today.total += parseInt(transaction.amount)
        } else if (transaction.hour === 19) {
          this.hour19.today.total += parseInt(transaction.amount)
        } else if (transaction.hour === 20) {
          this.hour20.today.total += parseInt(transaction.amount)
        } else if (transaction.hour === 21) {
          this.hour21.today.total += parseInt(transaction.amount)
        } else if (transaction.hour === 22) {
          this.hour22.today.total += parseInt(transaction.amount)
        } else if (transaction.hour === 23) {
          this.hour23.today.total += parseInt(transaction.amount)
        }
      })

      this.transactions2.forEach((transaction) => {
        if (this.transactionAxis.indexOf(transaction.hour) === -1) {
          this.transactionAxis.push(transaction.hour)
        }

        if (transaction.hour === 0) {
          this.hour0.todayMinus7Days.total += parseFloat(transaction.amount)
        }
        if (transaction.hour === 1) {
          this.hour1.todayMinus7Days.total += parseFloat(transaction.amount)
        }
        if (transaction.hour === 2) {
          this.hour2.todayMinus7Days.total += parseFloat(transaction.amount)
        }
        if (transaction.hour === 3) {
          this.hour3.todayMinus7Days.total += parseFloat(transaction.amount)
        }
        if (transaction.hour === 4) {
          this.hour4.todayMinus7Days.total += parseFloat(transaction.amount)
        }
        if (transaction.hour === 5) {
          this.hour5.todayMinus7Days.total += parseFloat(transaction.amount)
        }
        if (transaction.hour === 6) {
          this.hour6.todayMinus7Days.total += parseFloat(transaction.amount)
        }
        if (transaction.hour === 7) {
          this.hour7.todayMinus7Days.total += parseFloat(transaction.amount)
        }
        if (transaction.hour === 8) {
          this.hour8.todayMinus7Days.total += parseFloat(transaction.amount)
        }
        if (transaction.hour === 9) {
          this.hour9.todayMinus7Days.total += parseFloat(transaction.amount)
        }
        if (transaction.hour === 10) {
          this.hour10.todayMinus7Days.total += parseFloat(transaction.amount)
        }
        if (transaction.hour === 11) {
          this.hour11.todayMinus7Days.total += parseFloat(transaction.amount)
        }
        if (transaction.hour === 12) {
          this.hour12.todayMinus7Days.total += parseFloat(transaction.amount)
        }
        if (transaction.hour === 13) {
          this.hour13.todayMinus7Days.total += parseFloat(transaction.amount)
        }
        if (transaction.hour === 14) {
          this.hour14.todayMinus7Days.total += parseFloat(transaction.amount)
        }
        if (transaction.hour === 15) {
          this.hour15.todayMinus7Days.total += parseFloat(transaction.amount)
        }
        if (transaction.hour === 16) {
          this.hour16.todayMinus7Days.total += parseFloat(transaction.amount)
        }
        if (transaction.hour === 17) {
          this.hour17.todayMinus7Days.total += parseInt(transaction.amount)
        }
        if (transaction.hour === 18) {
          this.hour18.todayMinus7Days.total += parseInt(transaction.amount)
        }
        if (transaction.hour === 19) {
          this.hour19.todayMinus7Days.total += parseInt(transaction.amount)
        }
        if (transaction.hour === 20) {
          this.hour20.todayMinus7Days.total += parseInt(transaction.amount)
        }
        if (transaction.hour === 21) {
          this.hour21.todayMinus7Days.total += parseInt(transaction.amount)
        }
        if (transaction.hour === 22) {
          this.hour22.todayMinus7Days.total += parseInt(transaction.amount)
        }
        if (transaction.hour === 23) {
          this.hour23.todayMinus7Days.total += parseInt(transaction.amount)
        }
      })

      const allH = this.transactionAxis.sort((a, b) => a - b)
      allH.forEach((h) => {
        this.chartOptions.xaxis.categories.push(h + 'h')
        if (h === 0) {
          this.series[0].data.push(this.hour0.todayMinus7Days.total)
          this.series[1].data.push(this.hour0.today.total)
        }
        if (h === 1) {
          this.series[0].data.push(this.hour1.todayMinus7Days.total)
          this.series[1].data.push(this.hour1.today.total)
        }
        if (h === 2) {
          this.series[0].data.push(this.hour2.todayMinus7Days.total)
          this.series[1].data.push(this.hour2.today.total)
        }
        if (h === 3) {
          this.series[0].data.push(this.hour3.todayMinus7Days.total)
          this.series[1].data.push(this.hour3.today.total)
        }
        if (h === 4) {
          this.series[0].data.push(this.hour4.todayMinus7Days.total)
          this.series[1].data.push(this.hour4.today.total)
        }
        if (h === 5) {
          this.series[0].data.push(this.hour5.todayMinus7Days.total)
          this.series[1].data.push(this.hour5.today.total)
        }
        if (h === 6) {
          this.series[0].data.push(this.hour6.todayMinus7Days.total)
          this.series[1].data.push(this.hour6.today.total)
        }
        if (h === 7) {
          this.series[0].data.push(this.hour7.todayMinus7Days.total)
          this.series[1].data.push(this.hour7.today.total)
        }
        if (h === 8) {
          this.series[0].data.push(this.hour8.todayMinus7Days.total)
          this.series[1].data.push(this.hour8.today.total)
        }
        if (h === 9) {
          this.series[0].data.push(this.hour9.todayMinus7Days.total)
          this.series[1].data.push(this.hour9.today.total)
        }
        if (h === 10) {
          this.series[0].data.push(this.hour10.todayMinus7Days.total)
          this.series[1].data.push(this.hour10.today.total)
        }
        if (h === 11) {
          this.series[0].data.push(this.hour11.todayMinus7Days.total)
          this.series[1].data.push(this.hour11.today.total)
        }
        if (h === 12) {
          this.series[0].data.push(this.hour12.todayMinus7Days.total)
          this.series[1].data.push(this.hour12.today.total)
        }
        if (h === 13) {
          this.series[0].data.push(this.hour13.todayMinus7Days.total)
          this.series[1].data.push(this.hour13.today.total)
        }
        if (h === 14) {
          this.series[0].data.push(this.hour14.todayMinus7Days.total)
          this.series[1].data.push(this.hour14.today.total)
        }
        if (h === 15) {
          this.series[0].data.push(this.hour15.todayMinus7Days.total)
          this.series[1].data.push(this.hour15.today.total)
        }
        if (h === 16) {
          this.series[0].data.push(this.hour16.todayMinus7Days.total)
          this.series[1].data.push(this.hour16.today.total)
        }
        if (h === 17) {
          this.series[0].data.push(this.hour17.todayMinus7Days.total)
          this.series[1].data.push(this.hour17.today.total)
        }
        if (h === 18) {
          this.series[0].data.push(this.hour18.todayMinus7Days.total)
          this.series[1].data.push(this.hour18.today.total)
        }
        if (h === 19) {
          this.series[0].data.push(this.hour19.todayMinus7Days.total)
          this.series[1].data.push(this.hour19.today.total)
        }
        if (h === 20) {
          this.series[0].data.push(this.hour20.todayMinus7Days.total)
          this.series[1].data.push(this.hour20.today.total)
        }
        if (h === 21) {
          this.series[0].data.push(this.hour21.todayMinus7Days.total)
          this.series[1].data.push(this.hour21.today.total)
        }
        if (h === 22) {
          this.series[0].data.push(this.hour22.todayMinus7Days.total)
          this.series[1].data.push(this.hour22.today.total)
        }
        if (h === 23) {
          this.series[0].data.push(this.hour23.todayMinus7Days.total)
          this.series[1].data.push(this.hour23.today.total)
        }
      })
    } else if (isTuesday(new Date())) {
      // this.chartOptions.grid.padding.bottom = 0

      this.todayTransactions.forEach((transaction) => {
        this.transactions.push({
          hour: getHours(new Date(transaction.createdAt)),
          amount: transaction.amount,
          paymentType: transaction.paymentType,
          date: new Date(transaction.createdAt),
        })
      })

      this.todayMinusSevenDaysTransactions.forEach((transaction) => {
        this.transactions2.push({
          hour: getHours(new Date(transaction.createdAt)),
          amount: transaction.amount,
          paymentType: transaction.paymentType,
          date: new Date(transaction.createdAt),
        })
      })

      this.transactions.forEach((transaction) => {
        if (this.transactionAxis.indexOf(transaction.hour) === -1) {
          this.transactionAxis.push(transaction.hour)
        }

        if (transaction.hour === 0) {
          this.hour0.today.total += parseFloat(transaction.amount)
        } else if (transaction.hour === 1) {
          this.hour1.today.total += parseFloat(transaction.amount)
        } else if (transaction.hour === 2) {
          this.hour2.today.total += parseFloat(transaction.amount)
        } else if (transaction.hour === 3) {
          this.hour3.today.total += parseFloat(transaction.amount)
        } else if (transaction.hour === 4) {
          this.hour4.today.total += parseFloat(transaction.amount)
        } else if (transaction.hour === 5) {
          this.hour5.today.total += parseFloat(transaction.amount)
        } else if (transaction.hour === 6) {
          this.hour6.today.total += parseFloat(transaction.amount)
        } else if (transaction.hour === 7) {
          this.hour7.today.total += parseFloat(transaction.amount)
        } else if (transaction.hour === 8) {
          this.hour8.today.total += parseFloat(transaction.amount)
        } else if (transaction.hour === 9) {
          this.hour9.today.total += parseFloat(transaction.amount)
        } else if (transaction.hour === 10) {
          this.hour10.today.total += parseFloat(transaction.amount)
        } else if (transaction.hour === 11) {
          this.hour11.today.total += parseFloat(transaction.amount)
        } else if (transaction.hour === 12) {
          this.hour12.today.total += parseFloat(transaction.amount)
        } else if (transaction.hour === 13) {
          this.hour13.today.total += parseFloat(transaction.amount)
        } else if (transaction.hour === 14) {
          this.hour14.today.total += parseFloat(transaction.amount)
        } else if (transaction.hour === 15) {
          this.hour15.today.total += parseFloat(transaction.amount)
        } else if (transaction.hour === 16) {
          this.hour16.today.total += parseFloat(transaction.amount)
        } else if (transaction.hour === 17) {
          this.hour17.today.total += parseInt(transaction.amount)
        } else if (transaction.hour === 18) {
          this.hour18.today.total += parseInt(transaction.amount)
        } else if (transaction.hour === 19) {
          this.hour19.today.total += parseInt(transaction.amount)
        } else if (transaction.hour === 20) {
          this.hour20.today.total += parseInt(transaction.amount)
        } else if (transaction.hour === 21) {
          this.hour21.today.total += parseInt(transaction.amount)
        } else if (transaction.hour === 22) {
          this.hour22.today.total += parseInt(transaction.amount)
        } else if (transaction.hour === 23) {
          this.hour23.today.total += parseInt(transaction.amount)
        }
      })

      this.transactions2.forEach((transaction) => {
        if (this.transactionAxis.indexOf(transaction.hour) === -1) {
          this.transactionAxis.push(transaction.hour)
        }

        if (transaction.hour === 0) {
          this.hour0.todayMinus7Days.total += parseFloat(transaction.amount)
        }
        if (transaction.hour === 1) {
          this.hour1.todayMinus7Days.total += parseFloat(transaction.amount)
        }
        if (transaction.hour === 2) {
          this.hour2.todayMinus7Days.total += parseFloat(transaction.amount)
        }
        if (transaction.hour === 3) {
          this.hour3.todayMinus7Days.total += parseFloat(transaction.amount)
        }
        if (transaction.hour === 4) {
          this.hour4.todayMinus7Days.total += parseFloat(transaction.amount)
        }
        if (transaction.hour === 5) {
          this.hour5.todayMinus7Days.total += parseFloat(transaction.amount)
        }
        if (transaction.hour === 6) {
          this.hour6.todayMinus7Days.total += parseFloat(transaction.amount)
        }
        if (transaction.hour === 7) {
          this.hour7.todayMinus7Days.total += parseFloat(transaction.amount)
        }
        if (transaction.hour === 8) {
          this.hour8.todayMinus7Days.total += parseFloat(transaction.amount)
        }
        if (transaction.hour === 9) {
          this.hour9.todayMinus7Days.total += parseFloat(transaction.amount)
        }
        if (transaction.hour === 10) {
          this.hour10.todayMinus7Days.total += parseFloat(transaction.amount)
        }
        if (transaction.hour === 11) {
          this.hour11.todayMinus7Days.total += parseFloat(transaction.amount)
        }
        if (transaction.hour === 12) {
          this.hour12.todayMinus7Days.total += parseFloat(transaction.amount)
        }
        if (transaction.hour === 13) {
          this.hour13.todayMinus7Days.total += parseFloat(transaction.amount)
        }
        if (transaction.hour === 14) {
          this.hour14.todayMinus7Days.total += parseFloat(transaction.amount)
        }
        if (transaction.hour === 15) {
          this.hour15.todayMinus7Days.total += parseFloat(transaction.amount)
        }
        if (transaction.hour === 16) {
          this.hour16.todayMinus7Days.total += parseFloat(transaction.amount)
        }
        if (transaction.hour === 17) {
          this.hour17.todayMinus7Days.total += parseInt(transaction.amount)
        }
        if (transaction.hour === 18) {
          this.hour18.todayMinus7Days.total += parseInt(transaction.amount)
        }
        if (transaction.hour === 19) {
          this.hour19.todayMinus7Days.total += parseInt(transaction.amount)
        }
        if (transaction.hour === 20) {
          this.hour20.todayMinus7Days.total += parseInt(transaction.amount)
        }
        if (transaction.hour === 21) {
          this.hour21.todayMinus7Days.total += parseInt(transaction.amount)
        }
        if (transaction.hour === 22) {
          this.hour22.todayMinus7Days.total += parseInt(transaction.amount)
        }
        if (transaction.hour === 23) {
          this.hour23.todayMinus7Days.total += parseInt(transaction.amount)
        }
      })

      const allH = this.transactionAxis.sort((a, b) => a - b)
      allH.forEach((h) => {
        this.chartOptions.xaxis.categories.push(h + 'h')
        if (h === 0) {
          this.series[0].data.push(this.hour0.todayMinus7Days.total)
          this.series[1].data.push(this.hour0.today.total)
        }
        if (h === 1) {
          this.series[0].data.push(this.hour1.todayMinus7Days.total)
          this.series[1].data.push(this.hour1.today.total)
        }
        if (h === 2) {
          this.series[0].data.push(this.hour2.todayMinus7Days.total)
          this.series[1].data.push(this.hour2.today.total)
        }
        if (h === 3) {
          this.series[0].data.push(this.hour3.todayMinus7Days.total)
          this.series[1].data.push(this.hour3.today.total)
        }
        if (h === 4) {
          this.series[0].data.push(this.hour4.todayMinus7Days.total)
          this.series[1].data.push(this.hour4.today.total)
        }
        if (h === 5) {
          this.series[0].data.push(this.hour5.todayMinus7Days.total)
          this.series[1].data.push(this.hour5.today.total)
        }
        if (h === 6) {
          this.series[0].data.push(this.hour6.todayMinus7Days.total)
          this.series[1].data.push(this.hour6.today.total)
        }
        if (h === 7) {
          this.series[0].data.push(this.hour7.todayMinus7Days.total)
          this.series[1].data.push(this.hour7.today.total)
        }
        if (h === 8) {
          this.series[0].data.push(this.hour8.todayMinus7Days.total)
          this.series[1].data.push(this.hour8.today.total)
        }
        if (h === 9) {
          this.series[0].data.push(this.hour9.todayMinus7Days.total)
          this.series[1].data.push(this.hour9.today.total)
        }
        if (h === 10) {
          this.series[0].data.push(this.hour10.todayMinus7Days.total)
          this.series[1].data.push(this.hour10.today.total)
        }
        if (h === 11) {
          this.series[0].data.push(this.hour11.todayMinus7Days.total)
          this.series[1].data.push(this.hour11.today.total)
        }
        if (h === 12) {
          this.series[0].data.push(this.hour12.todayMinus7Days.total)
          this.series[1].data.push(this.hour12.today.total)
        }
        if (h === 13) {
          this.series[0].data.push(this.hour13.todayMinus7Days.total)
          this.series[1].data.push(this.hour13.today.total)
        }
        if (h === 14) {
          this.series[0].data.push(this.hour14.todayMinus7Days.total)
          this.series[1].data.push(this.hour14.today.total)
        }
        if (h === 15) {
          this.series[0].data.push(this.hour15.todayMinus7Days.total)
          this.series[1].data.push(this.hour15.today.total)
        }
        if (h === 16) {
          this.series[0].data.push(this.hour16.todayMinus7Days.total)
          this.series[1].data.push(this.hour16.today.total)
        }
        if (h === 17) {
          this.series[0].data.push(this.hour17.todayMinus7Days.total)
          this.series[1].data.push(this.hour17.today.total)
        }
        if (h === 18) {
          this.series[0].data.push(this.hour18.todayMinus7Days.total)
          this.series[1].data.push(this.hour18.today.total)
        }
        if (h === 19) {
          this.series[0].data.push(this.hour19.todayMinus7Days.total)
          this.series[1].data.push(this.hour19.today.total)
        }
        if (h === 20) {
          this.series[0].data.push(this.hour20.todayMinus7Days.total)
          this.series[1].data.push(this.hour20.today.total)
        }
        if (h === 21) {
          this.series[0].data.push(this.hour21.todayMinus7Days.total)
          this.series[1].data.push(this.hour21.today.total)
        }
        if (h === 22) {
          this.series[0].data.push(this.hour22.todayMinus7Days.total)
          this.series[1].data.push(this.hour22.today.total)
        }
        if (h === 23) {
          this.series[0].data.push(this.hour23.todayMinus7Days.total)
          this.series[1].data.push(this.hour23.today.total)
        }
      })
    } else {
      // this.chartOptions.grid.padding.bottom = 0

      // this week
      this.thisWeekTransactions.forEach((transaction) => {
        this.thisWeekDays.push({
          week: getWeek(new Date(transaction.date)),
          amount: transaction.total,
          paymentType: transaction.paymentType,
          date: new Date(transaction.date),
        })
      })

      this.thisWeekDays.push({
        week: getWeek(new Date()),
        amount: this.todayTotal,
        paymentType: null,
        date: new Date(),
      })

      this.thisWeekDays = this.thisWeekDays.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date)
      })

      this.thisWeekDays.forEach((day) => {
        if (!isToday(day.date)) {
          if (isMonday(day.date)) {
            this.chartOptions.xaxis.categories.push(
              `Pon: ${format(day.date, 'dd.MM.Y')}`
            )
            this.series[1].data.push(day.amount)
          }

          if (isTuesday(day.date)) {
            this.chartOptions.xaxis.categories.push(
              `Uto: ${format(day.date, 'dd.MM.Y')}`
            )
            this.series[1].data.push(day.amount)
          }

          if (isWednesday(day.date)) {
            this.chartOptions.xaxis.categories.push(
              `Sri: ${format(day.date, 'dd.MM.Y')}`
            )
            this.series[1].data.push(day.amount)
          }

          if (isThursday(day.date)) {
            this.chartOptions.xaxis.categories.push(
              `Cet: ${format(day.date, 'dd.MM.Y')}`
            )
            this.series[1].data.push(day.amount)
          }

          if (isFriday(day.date)) {
            this.chartOptions.xaxis.categories.push(
              `Pet: ${format(day.date, 'dd.MM.Y')}`
            )
            this.series[1].data.push(day.amount)
          }

          if (isSaturday(day.date)) {
            this.chartOptions.xaxis.categories.push(
              `Sub: ${format(day.date, 'dd.MM.Y')}`
            )
            this.series[1].data.push(day.amount)
          }

          if (isSunday(day.date)) {
            this.chartOptions.xaxis.categories.push(
              `Ned: ${format(day.date, 'dd.MM.Y')}`
            )
            this.series[1].data.push(day.amount)
          }
        }
      })

      // last week
      this.lastWeekTransactions.forEach((transaction) => {
        this.lastWeekDays.push({
          week: getWeek(new Date(transaction.date)),
          amount: transaction.total,
          paymentType: transaction.paymentType,
          date: new Date(transaction.date),
        })
      })

      this.lastWeekDays.push({
        week: getWeek(new Date()),
        amount: this.todayMinusSevenDaysTotal,
        paymentType: null,
        date: new Date(),
      })

      this.lastWeekDays = this.lastWeekDays.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date)
      })

      this.lastWeekDays.forEach((day, key) => {
        if (!isToday(day.date)) {
          if (isMonday(day.date)) {
            this.chartOptions.xaxis.categories[key] += ` - ${format(
              new Date(day.date),
              'dd.MM.yyyy'
            )}`
            this.series[0].data.push(day.amount)
          }

          if (isTuesday(day.date)) {
            this.chartOptions.xaxis.categories[key] += ` - ${format(
              new Date(day.date),
              'dd.MM.yyyy'
            )}`
            this.series[0].data.push(day.amount)
          }

          if (isWednesday(day.date)) {
            this.chartOptions.xaxis.categories[key] += ` - ${format(
              new Date(day.date),
              'dd.MM.yyyy'
            )}`
            this.series[0].data.push(day.amount)
          }

          if (isThursday(day.date)) {
            this.chartOptions.xaxis.categories[key] += ` - ${format(
              new Date(day.date),
              'dd.MM.yyyy'
            )}`
            this.series[0].data.push(day.amount)
          }

          if (isFriday(day.date)) {
            this.chartOptions.xaxis.categories[key] += ` - ${format(
              new Date(day.date),
              'dd.MM.yyyy'
            )}`
            this.series[0].data.push(day.amount)
          }

          if (isSaturday(day.date)) {
            this.chartOptions.xaxis.categories[key] += ` - ${format(
              new Date(day.date),
              'dd.MM.yyyy'
            )}`
            this.series[0].data.push(day.amount)
          }

          if (isSunday(day.date)) {
            this.chartOptions.xaxis.categories[key] += ` - ${format(
              new Date(day.date),
              'dd.MM.yyyy'
            )}`
            this.series[0].data.push(day.amount)
          }
        }
      })
    }

    setTimeout(() => {
      this.isReady = true
    }, 4000)
  },
  methods: {
    showWeeklyIndexHistory() {
      this.$emit('showWeeklyIndexHistory')
    },
  },
}
</script>
