<template>
  <div>
    <div id="dtl30d" class="card card-custom gutter-b">
      <div class="card-header border-0 pb-0">
        <h3 class="card-title">Devices trafic (last 30 days)</h3>
      </div>
      <div class="card-body py-0">
        <apexchart
          type="bar"
          v-if="isReady"
          :height="height"
          :options="chartOptions"
          :series="series"
        >
        </apexchart>
        <div v-else class="text-center w-100 pb-5">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { mapGetters } from 'vuex'
import format from 'date-fns/format'
import addDays from 'date-fns/addDays'
import axios from 'axios'

export default {
  name: 'DevicesTraffic',
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      series: [
        {
          name: 'Trafic',
          data: [],
          labels: {
            show: false,
          },
        },
      ],
      chartOptions: {
        tooltip: {
          enabled: true,
          x: {
            show: true,
            formatter(val) {
              return val
            },
          },
          y: {
            formatter(val) {
              return (
                val
                  .toFixed(2)
                  .replace('.', ',')
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
              )
            },
            title: {
              formatter(seriesName) {
                return seriesName
              },
            },
          },
        },
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            horizontal: true,
            dataLabels: {
              orientation: 'horizontal',
              position: 'bottom',
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: 25,
          offsetY: 0,
          formatter(val) {
            return (
              val
                .toFixed(2)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
            )
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          show: true,
          labels: {
            show: true,
            align: 'left',
            style: {
              colors: [],
              fontSize: '12px',
              fontWeight: 500,
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
          },
        },
        grid: {
          show: false,
        },
      },
      height: 0,
      isReady: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserCompany', 'currentUserCompanyOib']),
  },
  mounted() {
    const today = format(new Date(), 'yyyy-MM-dd')
    const todayMinus30Days = format(addDays(new Date(), -30), 'yyyy-MM-dd')

    axios
      .get(
        `${process.env.VUE_APP_ROUTE_API_URL}/api/v1/company-devices-traffic-in-range/${this.currentUserCompanyOib}/${todayMinus30Days}T00:00:00/${today}T00:00:00/`
      )
      .then(({ data }) => {
        data.devices.forEach((device) => {
          this.height = this.height + 90
          this.chartOptions.xaxis.categories.push([
            device.name ? device.name : '',
            device.location ? device?.location : '',
            device.lessor ? device?.lessor : '',
            device.tags ? device.tags : '',
          ])
          this.series[0].data.push(device.totalAmount.toFixed(2))
        })

        this.isReady = true
      })
  },
  methods: {},
}
</script>
