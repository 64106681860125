<template>
  <div>
    <VueApexCharts
      v-if="isReady"
      :options="options"
      :series="series"
    ></VueApexCharts>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { mapGetters } from 'vuex'
import getMonth from 'date-fns/getMonth'

export default {
  name: 'MonthlyHistoryCumulative',
  components: {
    VueApexCharts,
  },
  props: {
    traffic: {
      type: Array,
    },
  },
  data() {
    return {
      options: {
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            dataLabels: {
              orientation: 'vertical',
              position: 'bottom',
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -2,
          offsetY: 7,
          formatter(val) {
            return (
              val
                .toFixed(2)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
            )
          },
        },
        grid: {
          show: true,
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
            rotate: -90,
          },
        },
        yaxis: {
          labels: {
            show: true,
            formatter(val) {
              return (
                val
                  .toFixed(2)
                  .replace('.', ',')
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
              )
            },
          },
        },
        legend: {
          show: false,
        },
        title: {},
        colors: [
          function ({ dataPointIndex }) {
            if (dataPointIndex === 0) return '#ef8585'
            else if (dataPointIndex === 30) return '#989898'
            else return '#269ffb'
          },
        ],
      },
      series: [
        {
          name: 'Pay',
          data: [],
        },
      ],
      transactions: [],
      totalMonthAmount0: { name: '', total: 0 },
      totalMonthAmount1: { name: '', total: 0 },
      totalMonthAmount2: { name: '', total: 0 },
      totalMonthAmount3: { name: '', total: 0 },
      totalMonthAmount4: { name: '', total: 0 },
      totalMonthAmount5: { name: '', total: 0 },
      totalMonthAmount6: { name: '', total: 0 },
      totalMonthAmount7: { name: '', total: 0 },
      totalMonthAmount8: { name: '', total: 0 },
      totalMonthAmount9: { name: '', total: 0 },
      totalMonthAmount10: { name: '', total: 0 },
      totalMonthAmount11: { name: '', total: 0 },
      today: null,
      isReady: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserCompanyOib']),
  },
  mounted() {
    this.traffic = this.traffic.sort(function (a, b) {
      return new Date(b.dayFormat) - new Date(a.dayFormat)
    })

    this.traffic.forEach((day) => {
      this.transactions.push({
        month: getMonth(day.dayFormat),
        amount: day.value,
      })
    })

    this.transactions.forEach((transaction) => {
      if (transaction.month === 0) {
        this.totalMonthAmount0.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('January') === false)
          this.options.xaxis.categories.push('January')
      }
      if (transaction.month === 1) {
        this.totalMonthAmount1.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('February') === false)
          this.options.xaxis.categories.push('February')
      }
      if (transaction.month === 2) {
        this.totalMonthAmount2.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('March') === false)
          this.options.xaxis.categories.push('March')
      }
      if (transaction.month === 3) {
        this.totalMonthAmount3.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('April') === false)
          this.options.xaxis.categories.push('April')
      }
      if (transaction.month === 4) {
        this.totalMonthAmount4.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('May') === false)
          this.options.xaxis.categories.push('May')
      }
      if (transaction.month === 5) {
        this.totalMonthAmount5.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('June') === false)
          this.options.xaxis.categories.push('June')
      }
      if (transaction.month === 6) {
        this.totalMonthAmount6.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('July') === false)
          this.options.xaxis.categories.push('July')
      }
      if (transaction.month === 7) {
        this.totalMonthAmount7.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('August') === false)
          this.options.xaxis.categories.push('August')
      }
      if (transaction.month === 8) {
        this.totalMonthAmount8.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('September') === false)
          this.options.xaxis.categories.push('September')
      }
      if (transaction.month === 9) {
        this.totalMonthAmount9.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('October') === false)
          this.options.xaxis.categories.push('October')
      }
      if (transaction.month === 10) {
        this.totalMonthAmount10.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('November') === false)
          this.options.xaxis.categories.push('November')
      }
      if (transaction.month === 11) {
        this.totalMonthAmount11.total += parseFloat(transaction.amount)
        if (this.options.xaxis.categories.includes('December') === false)
          this.options.xaxis.categories.push('December')
      }
    })

    if (this.totalMonthAmount0.total)
      this.series[0].data.push(this.totalMonthAmount0.total)
    if (this.totalMonthAmount1.total)
      this.series[0].data.push(this.totalMonthAmount1.total)
    if (this.totalMonthAmount2.total)
      this.series[0].data.push(this.totalMonthAmount2.total)
    if (this.totalMonthAmount3.total)
      this.series[0].data.push(this.totalMonthAmount3.total)
    if (this.totalMonthAmount4.total)
      this.series[0].data.push(this.totalMonthAmount4.total)
    if (this.totalMonthAmount5.total)
      this.series[0].data.push(this.totalMonthAmount5.total)
    if (this.totalMonthAmount6.total)
      this.series[0].data.push(this.totalMonthAmount6.total)
    if (this.totalMonthAmount7.total)
      this.series[0].data.push(this.totalMonthAmount7.total)
    if (this.totalMonthAmount8.total)
      this.series[0].data.push(this.totalMonthAmount8.total)
    if (this.totalMonthAmount9.total)
      this.series[0].data.push(this.totalMonthAmount9.total)
    if (this.totalMonthAmount10.total)
      this.series[0].data.push(this.totalMonthAmount10.total)
    if (this.totalMonthAmount11.total)
      this.series[0].data.push(this.totalMonthAmount11.total)

    setTimeout(() => {
      this.isReady = true
    }, 500)
  },
}
</script>
